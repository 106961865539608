import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../../../components/Layout'
import SEO from '../../../components/seo'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import repairImg from '../../../assets/images/repair-img.jpg'

class gateRepairPage extends React.Component {
  render() {
    const siteTitle =
      'Automatic Gate Repair - Commercial - Residential - Driveway Gates'
    const siteDescription =
      'We repair automatic gates in Dallas, Fort Worth, and Arlington! Contact us today if you need your home or commercial gate repaired, including driveway gates, commercial gate systems, gate openers, and more!'

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />

        <div className="container-fluid text-center headline gates-headline">
          <div className="container">
            <h1 className="text-white m-0">Gate Repair</h1>
            <hr />
            <p className="text-white mx-auto mb-0">
              With locations in <Link to="/">Fort Worth</Link>,{' '}
              <Link to="/">Dallas</Link>, and <Link to="/">Arlington</Link> we
              service and repair all types of gates, openers, and access systems
              in Dallas-Fort Worth. We understand that the access to your
              property and your security is of the utmost importance, and make
              it a top priority to get the job done fast and right the first
              time. And, our repair and maintenance services will ensure that
              your gate remains in the best operating condition. A gate is
              useless unless it works properly at all times.{' '}
              <strong className="primary-color">
                If you need gate repair in Arlington, TX contact us today at{' '}
                <a href="tel:+1-817-466-2794">(817) 466-2794</a>
              </strong>
              .
            </p>
          </div>
        </div>

        <div className="container d-padding-t d-padding-b">
          <div className="row d-padding-b">
            <div className="col-md-7">
              <h4 className="mt-0">Residential Gate Repair</h4>
              <hr className="ml-0" />
              <p>
                Is your gate getting stuck or refusing to open at all? Was it
                recently damaged? Maybe it’s an older gate in need of a tune-up
                or a newer gate that just broke down. Whatever the case, we can
                help get your property or driveway gate fixed up quickly, no
                matter what kind it is. A broken driveway gate at your home is
                always a hassle, and the repair may not be an easy fix. We are
                available 24/7 to assist you so you can get your gate back to
                working condition fast!
              </p>
            </div>
            <div className="col-md-5">
              <LazyLoadImage
                className="w-100"
                src={repairImg}
                title="Gate Installation"
                alt="Gate"
              />
            </div>
          </div>

          <div className="set-width text-center mx-auto d-padding-b">
            <h4 className="mt-0">Commercial Gate Repair</h4>
            <hr />
            <p>
              Need a gate repaired at your office or work location? Do you need
              your <Link to="/">commercial gate system</Link> repaired? Our
              technicians are trained to handle any repair quickly and
              professionally so you can get back to business as usual. We are
              available to repair any of the following gate types at your
              commercial location:
            </p>
            <div className="row gates-row3 text-center mt-1 other-svc  ">
              <div className="col-md-3">
                <Link to="/">
                  <div className="gate-type-cont">
                    <h5>Access Control Systems</h5>
                  </div>
                </Link>
              </div>
              <div className="col-md-3">
                <Link to="/">
                  <div className="gate-type-cont">
                    <h5>Automatic Gates</h5>
                  </div>
                </Link>
              </div>
              <div className="col-md-3">
                <Link to="/">
                  <div className="gate-type-cont">
                    <h5>Electric Gates</h5>
                  </div>
                </Link>
              </div>
              <div className="col-md-3 ">
                <Link to="/">
                  <div className="gate-type-cont">
                    <h5>Secure Electronic Entry</h5>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div className="set-width text-center mx-auto d-padding-b">
            <h4 className="mt-0">Gate Opener Repairs</h4>
            <hr />
            <p>
              We proudly service some of the <Link to="/">biggest names</Link>{' '}
              in the gate automation industry, including:
            </p>
            <div className="row gates-row3 text-center mt-3 other-svc  mx-auto">
              <div className="col-md-4 ">
                <div className="gate-type-cont">
                  <h5>Apollo</h5>
                </div>
              </div>
              <div className="col-md-4">
                <div className="gate-type-cont">
                  <h5>Elite</h5>
                </div>
              </div>
              <div className="col-md-4">
                <div className="gate-type-cont">
                  <h5>LiftMaster</h5>
                </div>
              </div>
            </div>
            <p className="mt-3">
              If you have a gate or gate opener or an access system that needs
              repair, don’t hesitate to contact us for an estimate!
            </p>
          </div>

          <div className="set-width text-center mx-auto">
            <div>
              <h4 className="mt-0">Contact Us for Reliable Gate Repairs</h4>
              <hr />
              <p>
                From a rusted gate track to an electrical short in the automatic
                operating system, we’ve got you covered. And with quick
                turnaround time, we’ll have your gate up and running again in no
                time, and at an affordable price! If you need gate repair in
                Arlington, TX or anywhere in the Dallas-Fort Worth, TX area,
                contact J&J Gate Services at (817) 466-2794.
              </p>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default gateRepairPage

export const gateRepairPageQuery = graphql`
  query gateRepairPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
